import * as React from 'react';
import {Text} from 'wix-ui-tpa/cssVars';
import {classes, st} from './TotalsRow.st.css';
import {TotalsDataHook} from '../../Totals';

type RenderProp = () => React.ReactNode;
export interface TotalsRowProps {
  title: string | RenderProp;
  value: string | RenderProp;
  dataHook?: string;
  className?: string;
}

export const TotalsRow = ({title, value, className, dataHook}: TotalsRowProps) => {
  return (
    <dl
      data-hook={dataHook ?? /* istanbul ignore next */ TotalsDataHook.TotalsRow}
      className={st(classes.root, className)}>
      <dt>
        {typeof title === 'string' ? (
          <Text className={classes.totalTitleText} data-hook={TotalsDataHook.TotalsRowTitle}>
            {title}
          </Text>
        ) : (
          title()
        )}
      </dt>
      <dd data-hook={TotalsDataHook.TotalsRowValue}>
        {typeof value === 'string' ? <Text className={classes.totalTitleValue}>{value}</Text> : value()}
      </dd>
    </dl>
  );
};

TotalsRow.displayName = 'Totals.TotalsRow';
