import React, {useState, useEffect} from 'react';
import {StatesButton, StatesButtonStates, ThreeDotsLoader, ButtonSize} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../Widget/ControllerContext';
import {classes} from './PlaceOrderButton.st.css';
import {usePaymentsApi} from '../WithPaymentsApi/WithPaymentsApi';
import {ICashierPaymentsApi} from '@wix/cashier-payments-widget';
import {useFunctionResultObservation} from '@wix/function-result-observation';
import {useFedopsLogger, useErrorMonitor, useExperiments} from '@wix/yoshi-flow-editor';
import {FedopsInteractions, SPECS} from '../constants';
import {isAPaymentError, PaymentError} from '../../../types/payment.types';
import {CheckoutErrorCode} from '../../../domain/utils/errors';
import {toMonitorError} from '../../../domain/utils/errorMonitor.utils';

export const PlaceOrderButtonDataHook = 'place-order-button';

export const PlaceOrderButton = () => {
  const {experiments} = useExperiments();
  const localeKeys = useLocaleKeys();
  const errorMonitor = useErrorMonitor();

  const {paymentsApi} = usePaymentsApi();
  const {
    navigationStore: {navigateToThankYouPage},
    checkoutStore,
    paymentStore,
    deliveryMethodStore: {beforePlaceOrderClicked},
    checkboxesStore: {areCheckoutCheckboxesValid, isSubscriptionCheckboxChecked, setWasFormSubmitted},
  } = useControllerProps();
  const {isPlaceOrderButtonDisabled, placeOrderError, checkout, isFastFlow, setPlaceOrderPaymentError} = checkoutStore;
  const [successButtonState, setSuccessButtonState] = useState(StatesButtonStates.IDLE);
  const {withObservation} = useFunctionResultObservation();
  const clickPlaceOrderButton = withObservation(checkoutStore, 'clickPlaceOrderButton');
  const confirmPayment = withObservation(paymentStore, 'confirmPayment');
  const fedops = useFedopsLogger();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleSubmit = async () => {
    if (!areCheckoutCheckboxesValid) {
      setWasFormSubmitted(true);
      return;
    }

    setSuccessButtonState(StatesButtonStates.IN_PROGRESS);
    beforePlaceOrderClicked();

    const price = checkout.payNowTotalAfterGiftCard.amount;
    const detailsId = await getPaymentsDetailsId(
      paymentsApi,
      price,
      fedops,
      errorMonitor,
      setSuccessButtonState,
      setPlaceOrderPaymentError
    );
    /* istanbul ignore next */
    if (experiments.enabled(SPECS.ShouldNotCreateOrderAndCharge) && isAPaymentError(detailsId)) {
      return;
    }
    const paymentResponse = await clickPlaceOrderButton(isSubscriptionCheckboxChecked, detailsId as string | undefined);
    if (paymentResponse?.paymentError?.failureDetails) {
      const errorMessage =
        /* istanbul ignore next */ (await paymentsApi?.localizeError?.(
          paymentResponse?.paymentError?.failureDetails
        )) ?? localeKeys.checkout.page.generalPaymentError.subtitle();
      setPlaceOrderPaymentError(
        {
          translatedError: errorMessage,
          status: paymentResponse.paymentError.status,
          failureDetails: paymentResponse?.paymentError?.failureDetails,
        } as PaymentError,
        CheckoutErrorCode.PAYMENT_ERROR
      );
      return;
    }
    if (paymentsApi && paymentResponse?.paymentResponseToken && price > 0) {
      try {
        await paymentsApi.continuePayment?.(paymentResponse.paymentResponseToken);
      } catch (e: unknown) {
        /* istanbul ignore next */
        errorMonitor.captureException(...toMonitorError(e, 'PlaceOrderButton continuePayment'));
        /* istanbul ignore next */
        const paymentError = e as PaymentError;
        /* istanbul ignore next */
        if (paymentError.status === 'BUYER_CANCELED') {
          setSuccessButtonState(StatesButtonStates.IDLE);
        } else {
          /* istanbul ignore next */
          setPlaceOrderPaymentError(paymentError, CheckoutErrorCode.CUSTOM_PAYMENT_ERROR);
        }
        /* istanbul ignore next */
        return;
      }
    }
    if (paymentResponse?.orderId || paymentResponse?.subscriptionId) {
      if (isFastFlow && paymentResponse.paymentResponseToken) {
        /* istanbul ignore next */
        if (experiments.enabled(SPECS.Fix3DSInExpressMethodsFastFlow)) {
          // https://github.com/wix-private/cashier-client/blob/master/packages/express-checkout-widget/docs/Payment%20confirmation.md#payment-confirmation
          const result = await confirmPayment({
            chargeResponseToken: paymentResponse.paymentResponseToken,
            newApi: true,
          });
          /* istanbul ignore next */
          if (result.clientStatus !== 'Pending') {
            void navigateToThankYouPage({
              orderId: paymentResponse.orderId || paymentResponse.subscriptionId,
              isSubscription: Boolean(paymentResponse?.subscriptionId),
            });
          }
          /* istanbul ignore next */
          return;
          /* istanbul ignore next */
        } else {
          await confirmPayment({
            chargeResponseToken: paymentResponse.paymentResponseToken,
            newApi: false,
          });
        }
      }
      void navigateToThankYouPage({
        orderId: paymentResponse.orderId || paymentResponse.subscriptionId,
        isSubscription: Boolean(paymentResponse?.subscriptionId),
      });
    }
  };

  useEffect(() => {
    if (placeOrderError) {
      setSuccessButtonState(StatesButtonStates.IDLE);
    }
  }, [placeOrderError]);

  function getPlaceOrderButton() {
    return (
      <StatesButton
        className={classes.placeOrderButton}
        data-hook={PlaceOrderButtonDataHook}
        disabled={isPlaceOrderButtonDisabled}
        upgrade={true}
        size={ButtonSize.medium}
        onClick={() => {
          void handleSubmit();
        }}
        idleContent={
          experiments.enabled(SPECS.ChangeNameOfPlaceOrderButton)
            ? localeKeys.checkout.placeOrderPay.button()
            : localeKeys.checkout.place_order.place_order_button()
        }
        state={successButtonState}
        inProgressContent={<ThreeDotsLoader className={classes.threeDotButton} />}
      />
    );
  }
  return getPlaceOrderButton();
};

async function getPaymentsDetailsId(
  paymentsApi: ICashierPaymentsApi | undefined,
  price: number,
  fedops: ReturnType<typeof useFedopsLogger>,
  errorMonitor: ReturnType<typeof useErrorMonitor>,
  setSuccessButtonState: React.Dispatch<React.SetStateAction<StatesButtonStates>>,
  setPlaceOrderPaymentError: (paymentError: PaymentError, errorCode: string) => void
): Promise<string | undefined | PaymentError> {
  if (!paymentsApi || price === 0) {
    return;
  }
  fedops.interactionStarted(FedopsInteractions.InitializePaymentInPlaceOrderInteraction);
  try {
    const initializeResponse = await paymentsApi?.initializePayment?.();
    fedops.interactionEnded(FedopsInteractions.InitializePaymentInPlaceOrderInteraction);
    return initializeResponse?.detailsId;
  } catch (e: unknown) {
    /* istanbul ignore next */
    errorMonitor.captureException(...toMonitorError(e, 'PlaceOrderButton getPaymentsDetailsId'));
    /* istanbul ignore next */
    const paymentError = e as PaymentError;
    /* istanbul ignore next */
    setPlaceOrderPaymentError(paymentError, CheckoutErrorCode.CUSTOM_PAYMENT_ERROR);
    /* istanbul ignore next */
    return paymentError;
  }
}
