import {StatesButton, StatesButtonStates, ThreeDotsLoader, ButtonSize} from 'wix-ui-tpa/cssVars';
import React from 'react';
import {classes} from './NextStepButton.st.css';

export interface NextStepButtonProps {
  text: string;
  dataHook: string;
  onClick?: () => void;
  disabled?: boolean;
  buttonState: StatesButtonStates;
}

export const NextStepButton = ({text, dataHook, onClick, disabled, buttonState}: NextStepButtonProps) => {
  return (
    <StatesButton
      disabled={!!disabled}
      size={ButtonSize.medium}
      className={classes.NextStepButton}
      onClick={onClick}
      state={buttonState}
      upgrade={true}
      data-hook={dataHook}
      idleContent={text}
      inProgressContent={<ThreeDotsLoader className={classes.threeDotButton} />}
    />
  );
};
