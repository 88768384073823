import React, {useRef} from 'react';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {DynamicStep, DynamicStepComponentProps} from '../../StepsManager/Components/DynamicStep';
import {StepHeader} from '../../StepsManager/Components/StepHeader';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {StepImplementationProps, StepState} from '../../../../../types/app.types';
import {PlaceOrder} from './Components/PlaceOrder/PlaceOrder';

export enum PaymentAndPlaceOrderDataHook {
  root = 'PaymentAndPlaceOrderDataHook.root',
  header = 'PaymentAndPlaceOrderDataHook.header',
  open = 'PaymentAndPlaceOrderDataHook.open',
}

const InternalPaymentAndPlaceOrderStep = ({index}: DynamicStepComponentProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const localeKeys = useLocaleKeys();

  const {
    stepsManagerStore: {stepsList},
  } = useControllerProps();

  const stepState = stepsList[index].state;

  return (
    <>
      <StepHeader
        index={index}
        dataHook={PaymentAndPlaceOrderDataHook.header}
        label={localeKeys.checkout.payment()}
        ref={headerRef}
      />
      {stepState === StepState.OPEN && (
        <div data-hook={PaymentAndPlaceOrderDataHook.open}>
          <PlaceOrder />
        </div>
      )}
    </>
  );
};

export const PaymentAndPlaceOrderStep = ({index}: StepImplementationProps) => {
  return (
    <DynamicStep
      index={index!}
      dataHook={PaymentAndPlaceOrderDataHook.root}
      component={InternalPaymentAndPlaceOrderStep}
    />
  );
};
