import {AddressWithContactModel} from '../models/AddressWithContact.model';
import {ContactModel} from '../models/Contact.model';
import {AddressModel} from '../models/Address.model';

export function isShippingDestinationSignificant(
  addressWithContact: AddressWithContactModel | undefined,
  isShippingFlow: boolean
) {
  if (!isShippingFlow) {
    return isContactDefined(addressWithContact?.contact);
  }

  return isAddressSignificant(addressWithContact?.address);
}

function isContactDefined(contact?: ContactModel) {
  return Object.values(contact ?? /* istanbul ignore next */ {}).filter((value) => !!value).length > 0;
}

export function isAddressSignificant(address?: AddressModel) {
  const {country, subdivision, postalCode, subdivisionFullname, countryFullname, ...rest} =
    address ?? /* istanbul ignore next */ {};
  return Object.values(rest ?? /* istanbul ignore next */ {}).filter((value) => !!value).length > 0;
}
