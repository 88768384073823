import React from 'react';
import {Trans} from 'react-i18next';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {Text} from 'wix-ui-tpa/cssVars';
import {
  PolicyButtonWithDialog,
  PolicyButtonLocation,
  PolicyType,
} from '../../PolicyButtonWithDialog/PolicyButtonWithDialog';
import {classes} from '../Checkboxes.st.css';

export interface PolicyInfo {
  labelKey: string;
  dialogTitle: string;
  dataHook: string;
  enabled?: boolean | null;
  value?: string | null;
  policyType: PolicyType;
  location: PolicyButtonLocation;
}

export const PoliciesText = ({dataHook, policies}: {dataHook: string; policies: PolicyInfo[]}) => {
  const listOfPolicies = policies.reduce<React.ReactElement | undefined>((acc, policy, index) => {
    if (acc === undefined) {
      return <PolicyText policy={policy} />;
    }

    if (index === policies.length - 1) {
      return <AddLastItemToList list={acc} item={<PolicyText policy={policy} />} />;
    }

    return <AddItemToList item={<PolicyText policy={policy} />} list={acc} />;
  }, undefined);

  return (
    <Text data-hook={dataHook}>
      <PolicyLabel listOfPolicies={listOfPolicies!} />
    </Text>
  );
};

export const PolicyLabel = ({listOfPolicies}: {listOfPolicies: React.ReactElement}) => {
  const {t, i18n} = useTranslation();
  return (
    <Trans i18n={i18n} t={t} i18nKey="checkout.terms_and_conditions.checkbox.label" components={[listOfPolicies]} />
  );
};

export const AddItemToList = ({item, list}: {item: React.ReactElement; list: React.ReactElement}) => {
  const {t, i18n} = useTranslation();
  return (
    <Trans
      i18n={i18n}
      t={t}
      i18nKey="checkout.terms_and_conditions.checkbox.add_item_to_list"
      components={[list, item]}
    />
  );
};

export const AddLastItemToList = ({item, list}: {item: React.ReactElement; list: React.ReactElement}) => {
  const {t, i18n} = useTranslation();
  return (
    <Trans i18n={i18n} t={t} i18nKey="checkout.terms_and_conditions.checkbox.add_last_item" components={[list, item]} />
  );
};

export const PolicyText = ({policy}: {policy: PolicyInfo}) => {
  const {t, i18n} = useTranslation();
  return (
    <Trans
      i18n={i18n}
      t={t}
      i18nKey={policy.labelKey}
      components={[
        <PolicyButtonWithDialog
          className={classes.policy}
          key={policy.labelKey}
          show={true}
          dialogTitle={policy.dialogTitle}
          dataHook={policy.dataHook}
          location={policy.location}
          policyType={policy.policyType}
          dialogDescription={policy.value!}>
          content
        </PolicyButtonWithDialog>,
      ]}
    />
  );
};
