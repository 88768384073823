import React, {useEffect, useState} from 'react';
import {useFormInstance} from '../../Form/useFormInstance';
import {ExtendedFieldsForm} from '../../Form/ExtendedFieldsForm/ExtendedFieldsForm';
import {ExtendedFieldsFragment} from '../../../../gql/graphql';
import {getExtendedFieldsFormInitialState} from '../../Form/ExtendedFieldsForm/ExtendedFieldsForm.utils';
import {ButtonPriority, StatesButton, StatesButtonStates, ThreeDotsLoader} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from './StandaloneExtendedFieldsForm.st.css';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';

export enum StandaloneExtendedFieldsFormDataHook {
  root = 'StandaloneExtendedFieldsForm.extendedFieldsForm',
  apply = 'StandaloneExtendedFieldsForm.apply',
}

const StandaloneExtendedFieldsFormInternal = ({extendedFields}: {extendedFields?: ExtendedFieldsFragment}) => {
  const localeKeys = useLocaleKeys();
  const formInstance = useFormInstance(() => getExtendedFieldsFormInitialState(extendedFields));
  const {checkoutStore} = useControllerProps();
  const [buttonState, setButtonState] = useState<StatesButtonStates>(StatesButtonStates.IDLE);
  const {setExtendedFields} = checkoutStore;

  const onApplyClick = async () => {
    if (await formInstance.isValid()) {
      setButtonState(StatesButtonStates.IN_PROGRESS);
      await setExtendedFields(formInstance.data.formValues);
    }
  };

  useEffect(
    () => {
      if (buttonState === StatesButtonStates.IN_PROGRESS) {
        setButtonState(StatesButtonStates.SUCCESS);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [extendedFields]
  );

  return (
    <div className={classes.root} data-hook={StandaloneExtendedFieldsFormDataHook.root}>
      <ExtendedFieldsForm {...formInstance.data} />
      <StatesButton
        data-hook={StandaloneExtendedFieldsFormDataHook.apply}
        upgrade
        state={buttonState}
        idleContent={localeKeys.checkout.additionalInfo.custom_field.apply()}
        inProgressContent={<ThreeDotsLoader />}
        onNotificationEnd={() => setButtonState(StatesButtonStates.IDLE)}
        fullWidth
        priority={ButtonPriority.basicSecondary}
        className={classes.applyButton}
        onClick={() => {
          void onApplyClick();
        }}
      />
    </div>
  );
};

export const StandaloneExtendedFieldsForm = React.memo(StandaloneExtendedFieldsFormInternal);
