import {FORMS_TEMPLATE_IDS} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import React from 'react';
import {FormProps} from '../ContactForm/ContactForm';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useICUI18n} from '../../Hooks/useICUI18n';
import {getAdditionalInfoFormOverrides} from './AdditionalInfoForm.utils';

const AdditionalInfoFormInternal = ({formRef, formValues, setFormValues, formErrors, setFormErrors}: FormProps) => {
  const {
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  const i18n = useICUI18n();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={getAdditionalInfoFormOverrides({checkoutSettings})}
      formId={FORMS_TEMPLATE_IDS.ADDITIONAL_INFO}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
    />
  );
};

export const AdditionalInfoForm = React.memo(AdditionalInfoFormInternal);
