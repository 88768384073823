import React from 'react';
import {Text} from 'wix-ui-tpa/cssVars';
import {Checkboxes} from '../../../../../Checkboxes/Checkboxes';
import {classes} from './PlaceOrder.st.css';
import {useLocaleKeys} from '../../../../../../../locale-keys/LocaleKeys';
import {ViolationsAndPlaceOrderButton} from '../../../../../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {ViolationsAndPlaceOrderButtonLocation} from '../../../../../../../types/app.types';

export enum PlaceOrderSectionDataHook {
  root = 'PlaceOrderSectionDataHook.root',
  subtitle = 'PlaceOrderSectionDataHook.subtitle',
  violations = 'PlaceOrderSectionDataHook.violations',
}

export const PlaceOrder = () => {
  const localeKeys = useLocaleKeys();

  return (
    <div className={classes.root} data-hook={PlaceOrderSectionDataHook.root}>
      <div className={classes.subtitle}>
        <Text data-hook={PlaceOrderSectionDataHook.subtitle}>{localeKeys.checkout.place_order.description()}</Text>
      </div>
      <Checkboxes />
      <ViolationsAndPlaceOrderButton location={ViolationsAndPlaceOrderButtonLocation.paymentAndPlaceOrderStep} />
    </div>
  );
};
