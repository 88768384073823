import React, {useEffect, useState} from 'react';
import {Button, ButtonPriority, TextField} from 'wix-ui-tpa/cssVars';
import {classes} from './CodeInput.st.css';

export enum CodesInputDataHooks {
  TextInput = 'CodesInput.TextInput',
  Button = 'CodesInput.Button',
}

export interface CodeInputProps {
  dataHook?: string;
  placeholder?: string;
  appliedValue?: string;
  applyText: string;
  removeText: string;
  onApply: (code: string) => void;
  onRemove: () => void;
  error?: string;
}

export const CodeInput = ({
  dataHook,
  placeholder,
  appliedValue,
  applyText,
  removeText,
  onApply,
  onRemove,
  error,
}: CodeInputProps) => {
  const [value, setValue] = useState(appliedValue ?? '');
  const [isApplied, setIsApplied] = useState(!!appliedValue);

  useEffect(() => {
    setValue(appliedValue ?? '');
    setIsApplied(!!appliedValue);
  }, [appliedValue]);

  const onButtonClick = () => {
    if (isApplied) {
      onRemove();
      /* istanbul ignore if */
      if (value !== appliedValue) {
        setValue('');
        setIsApplied(false);
      }
    } else {
      setIsApplied(true);
      onApply(value);
    }
  };

  return (
    <div data-hook={dataHook}>
      <TextField
        data-hook={CodesInputDataHooks.TextInput}
        className={classes.textField}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onClear={() => setValue('')}
        successIcon
        withClearButton={!isApplied}
        disabled={isApplied}
        success={isApplied && !error}
        error={isApplied && !!error}
        errorMessage={error}
        newErrorMessage
      />
      <Button
        className={classes.statesButton}
        data-hook={CodesInputDataHooks.Button}
        upgrade
        fullWidth
        priority={ButtonPriority.basic}
        disabled={!value}
        onClick={onButtonClick}>
        {isApplied ? removeText : applyText}
      </Button>
    </div>
  );
};
