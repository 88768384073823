import React from 'react';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {MAX_ITEM_OPTION_LENGTH, NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE} from '../../constants';
import {LineItemModel} from '../../../../domain/models/LineItem.model';
import {LineItemOptionModel} from '../../../../domain/models/LineItemOption.model';
import {ItemLayout} from './ItemLayout';
import {ItemName} from './ItemName/ItemName';
import {ItemPrice} from './ItemPrice/ItemPrice';
import {ItemOptions} from './ItemOptions/ItemOptions';
import {ItemSubscription} from './ItemSubscription/ItemSubscription';
import {classes} from './LineItem.st.css';
import {ItemImage} from './ItemImage/ItemImage';
import {getSubscriptionData, getPaymentTypeLabel} from './LineItem.utils';
import {ComWixEcommerceCatalogSpiApiV1PaymentOptionType} from '../../../../gql/graphql';
import {LineItemAdditionalInfo} from './LineItemAdditionalInfo';

export enum LineItemDataHooks {
  LineItemWrapper = 'LineItemDataHooks.LineItemWrapper',
  Name = 'LineItemDataHooks.Name',
  ComparePrice = 'LineItemDataHooks.ComparePrice',
  Price = 'LineItemDataHooks.Price',
  Subscription = 'LineItemDataHooks.Subscription',
  Options = 'LineItemDataHooks.Options',
  OutOfStock = 'LineItemDataHooks.OutOfStock',
  Image = 'LineItemDataHooks.Image',
  DefaultImage = 'LineItemDataHooks.DefaultImage',
}

export const LineItemRow = ({
  lineItem,
  useLayoutForLongPrice,
}: {
  lineItem: LineItemModel;
  useLayoutForLongPrice: boolean;
}) => {
  const localeKeys = useLocaleKeys();
  const hidePrice = lineItem.renderingConfig?.hidePrice;

  const getQuantityAsLineItemOption = () => {
    return new LineItemOptionModel({
      value: `${lineItem.quantity}`,
      title: localeKeys.checkout.order_summary.items.quantity(),
    });
  };

  const getSKUAsLineItemOption = () => {
    return new LineItemOptionModel({
      value: lineItem.sku!,
      title: localeKeys.checkout.order_summary.items.sku(),
    });
  };

  const getPaymentTypeAsLineOption = () => {
    return new LineItemOptionModel({
      value: getPaymentTypeLabel(lineItem, localeKeys),
      title: '',
    });
  };

  const getLineItemOptions = (): {title: string; value: string}[] => {
    const options = [];
    if (lineItem.paymentOption !== ComWixEcommerceCatalogSpiApiV1PaymentOptionType.FULL_PAYMENT_ONLINE) {
      options.push(getPaymentTypeAsLineOption());
    }
    if (!lineItem.renderingConfig?.hideQuantity) {
      options.push(getQuantityAsLineItemOption());
    }
    lineItem.sku && options.push(getSKUAsLineItemOption());
    options.push(...lineItem.options);
    return options;
  };

  const getSalePriceAriaLabel = () => {
    return lineItem.prices.comparePrice
      ? localeKeys.checkout.orderSummary.salePrice.ariaLabel({
          price: lineItem.prices.lineItemPrice.formattedAmount,
        })
      : localeKeys.checkout.orderSummary.price.ariaLabel({
          price: lineItem.prices.lineItemPrice.formattedAmount,
        });
  };

  return (
    <li data-hook={LineItemDataHooks.LineItemWrapper}>
      <ItemLayout
        descriptionWithColumns={!useLayoutForLongPrice}
        Icon={<ItemImage itemMedia={lineItem.media} />}
        MainDescription={<ItemName name={lineItem.productName} />}
        SecondaryDescription={
          hidePrice ? null : (
            <ItemPrice
              shortPrice={!useLayoutForLongPrice}
              formattedPrice={lineItem.prices.lineItemPrice.formattedAmount}
              priceAriaLabel={getSalePriceAriaLabel()}
              formattedComparePrice={lineItem.prices.comparePrice?.formattedAmount}
              comparePriceAriaLabel={
                lineItem.prices.comparePrice
                  ? localeKeys.checkout.orderSummary.regularPrice.ariaLabel({
                      price: lineItem.prices.comparePrice?.formattedAmount,
                    })
                  : undefined
              }
            />
          )
        }
        Details={
          <div className={classes.itemData}>
            {lineItem.subscription && (
              <ItemSubscription subscription={getSubscriptionData(lineItem.subscription, localeKeys)} />
            )}
            <ItemOptions
              itemId={lineItem.id}
              options={getLineItemOptions()}
              maxOptionValLength={MAX_ITEM_OPTION_LENGTH}
              collapseSettings={{
                numberOfOptionsToCollapseAfter: NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE + 1,
                showMoreTitle: localeKeys.checkout.order_summary.items.more_details(),
                showLessTitle: localeKeys.checkout.order_summary.items.lessDetails(),
              }}
            />
          </div>
        }
        AdditionalInfo={<LineItemAdditionalInfo lineItem={lineItem} />}
      />
    </li>
  );
};
