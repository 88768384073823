import {FormValues} from '@wix/form-viewer/widget';
import {CustomFieldModel} from '../../../../domain/models/CustomField.model';
import {CheckoutSettingsModel} from '../../../../domain/models/checkoutSettings/CheckoutSettings.model';

export const getAdditionalInfoFormInitialState = (customField?: CustomFieldModel) => ({
  additional_info_1: customField?.value ?? '',
});

export const getCustomFieldFromAdditionalInfoFormValues = (additionalInfoFormValues: FormValues): string =>
  additionalInfoFormValues.additional_info_1 as string;

export const getAdditionalInfoFormOverrides = ({checkoutSettings}: {checkoutSettings: CheckoutSettingsModel}) => {
  return {
    additional_info_1: {
      label: checkoutSettings.customField.title,
      required: checkoutSettings.customField.mandatory,
    },
  };
};
