import {ItemMediaModel} from '../../../../../domain/models/ItemMedia.model';
import {Image} from 'wix-ui-tpa/cssVars';
import {classes} from './ItemImage.st.css';
import {DEFAULT_IMAGE_ICON_SIZE, THUMBNAIL_WIDTH} from '../../../constants';
import React from 'react';
import {LineItemDataHooks} from '../LineItem';

export const ItemImage = ({itemMedia}: {itemMedia?: ItemMediaModel}) => {
  return itemMedia ? (
    <Image
      data-hook={LineItemDataHooks.Image}
      src={itemMedia.src}
      width={THUMBNAIL_WIDTH}
      height={itemMedia.height && itemMedia.width ? (itemMedia.height * THUMBNAIL_WIDTH) / itemMedia.width : undefined}
      alt={itemMedia.altText!}
    />
  ) : (
    <div data-hook={LineItemDataHooks.DefaultImage} className={classes.defaultImage}>
      <svg viewBox="0 0 32 32" fill="currentColor" width={DEFAULT_IMAGE_ICON_SIZE} height={DEFAULT_IMAGE_ICON_SIZE}>
        <path
          fill="currentColor"
          d="M30.026 3.502h-8.903c0.279-0.503 0.354-1.118 0.186-1.732-0.168-0.633-0.652-1.173-1.267-1.471s-1.267-0.298-1.863-0.037c-0.801 0.354-1.453 1.304-2.254 2.515-0.801-1.211-1.434-2.161-2.254-2.515-0.596-0.261-1.267-0.242-1.863 0.037-0.615 0.298-1.080 0.838-1.267 1.471-0.168 0.615-0.093 1.229 0.186 1.732h-8.755c-0.95 0-1.714 0.764-1.714 1.714v25.071c0 0.95 0.764 1.714 1.714 1.714h28.051c0.95 0 1.714-0.764 1.714-1.714v-25.071c0-0.95-0.764-1.714-1.714-1.714zM18.645 1.304c0.391-0.168 0.727-0.056 0.913 0.019 0.317 0.149 0.559 0.428 0.652 0.745 0.056 0.223 0.186 0.969-0.689 1.434h-2.682c0.652-1.006 1.285-1.975 1.807-2.198zM11.66 2.067c0.075-0.298 0.335-0.577 0.652-0.745 0.168-0.075 0.521-0.205 0.913-0.019 0.521 0.223 1.155 1.173 1.807 2.198h-2.682c-0.875-0.447-0.745-1.211-0.689-1.434zM1.378 30.287v-25.071c0-0.335 0.261-0.596 0.596-0.596h13.374v26.263h-13.374c-0.335 0-0.596-0.261-0.596-0.596zM30.622 30.287c0 0.335-0.261 0.596-0.596 0.596h-13.56v-26.263h13.56c0.335 0 0.596 0.261 0.596 0.596v25.071z"
        />
      </svg>
    </div>
  );
};
