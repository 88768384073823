import {LineItemModel} from '../../../../domain/models/LineItem.model';
import {Violation} from '../../Violations/Violation';
import React from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {getLineItemViolationIfItExists} from './LineItem.utils';
import {ItemOutOfStock} from './ItemOutOfStock/ItemOutOfStock';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';

export const LineItemAdditionalInfo = ({lineItem}: {lineItem: LineItemModel}) => {
  const {
    checkoutStore: {removeLineItem, shouldShowViolations, checkout},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const isItemOutOfStock = lineItem.quantity === 0;
  const lineItemViolation = shouldShowViolations
    ? getLineItemViolationIfItExists(checkout.violations, lineItem.id)
    : undefined;

  if (isItemOutOfStock) {
    return (
      <ItemOutOfStock
        message={localeKeys.checkout.order_summary.item_out_of_stock_error()}
        removeButtonText={localeKeys.checkout.order_summary.removeFromCartError.cta()}
        removeButtonOnClick={() => void removeLineItem(lineItem.id)}
      />
    );
  } else if (lineItemViolation) {
    return <Violation severity={lineItemViolation.severity} description={lineItemViolation.description} />;
  }

  return null;
};
