import React, {useEffect, useState} from 'react';
import {Card, DatePickerInput, Dropdown, DropdownOptionProps, DropdownTheme} from 'wix-ui-tpa/cssVars';

import {TimeSlotOption} from '../../../../domain/models/ShippingOption.model';
import {classes} from './TimeSlotSelector.st.css';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {dateKeyFromDate} from '../../../../domain/utils/dateTime.utils';

export enum TimeSlotSelectorDataHook {
  root = 'TimeSlotSelectorDataHook.root',
  dropdown = 'TimeSlotSelectorDataHook.dropdown',
  timePickerDropdown = 'TimeSlotSelectorDataHook.timePickerDropdown',
}

export interface TimeSlotSelectorProps {
  timeSlotDays: {[dateString: string]: TimeSlotOption[]};
  selectedId?: string;
  disabled?: boolean;
  onSelect: (shippingOptionId: string) => void;
  hasDifferentPricedTimeSlots?: boolean;
}

export const TimeSlotSelector = ({
  timeSlotDays,
  onSelect,
  disabled,
  selectedId,
  hasDifferentPricedTimeSlots,
}: TimeSlotSelectorProps) => {
  const localeKeys = useLocaleKeys();

  const findOptionById = () => {
    return Object.keys(timeSlotDays)
      .flatMap((day) => timeSlotDays[day])
      .find((option) => option.id === selectedId);
  };

  const mapToTimePickerDropdownOptions = (t: TimeSlotOption[]): DropdownOptionProps[] => {
    return t.map((timeOption) => {
      return {
        id: timeOption.id,
        value: formatTime(timeOption),
        isSelectable: true,
      };
    });
  };

  const formatTime = (timeOption: TimeSlotOption) => {
    const isFree = timeOption.price.amount === 0;
    return hasDifferentPricedTimeSlots
      ? localeKeys.checkout.deliveryMethod.shippingWithDeliveryTime.dropdownOption({
          time: timeOption.timeString,
          price: isFree ? localeKeys.checkout.delivery_method.free_label() : timeOption.price.formattedAmount,
        })
      : timeOption.timeString;
  };

  const [selectedOption, setSelectedOption] = useState(findOptionById);

  useEffect(() => setSelectedOption(findOptionById()), /* eslint-disable react-hooks/exhaustive-deps */ [selectedId]);

  return (
    <div className={classes.root}>
      <Card data-hook={TimeSlotSelectorDataHook.root} className={classes.dateTimePicker}>
        <DatePickerInput
          inputWidth={''}
          label={localeKeys.checkout.deliveryMethod.date.label()}
          className={classes.date}
          disabled={disabled}
          value={selectedOption!.dateAtStartOfDay}
          onChange={
            /* istanbul ignore next */ (date: Date) => {
              onSelect(timeSlotDays[dateKeyFromDate(date)][0].id);
            }
          }
          filterDate={/* istanbul ignore next */ (date) => Boolean(timeSlotDays[dateKeyFromDate(date)])}
          removeClearButton={true}
          data-hook={TimeSlotSelectorDataHook.dropdown}
        />
        <Dropdown
          data-hook={TimeSlotSelectorDataHook.timePickerDropdown}
          className={classes.time}
          label={localeKeys.checkout.deliveryMethod.time.label()}
          upgrade={true}
          theme={DropdownTheme.Box}
          disabled={disabled}
          initialSelectedId={selectedOption?.id}
          options={mapToTimePickerDropdownOptions(timeSlotDays[selectedOption!.dateKey])}
          onChange={(option) => onSelect(option.id!)}
        />
      </Card>
    </div>
  );
};
