import React, {useState} from 'react';
import {Checkbox, Text} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../Widget/ControllerContext';
import {ILocaleKeys, useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {PoliciesText, PolicyInfo} from './PoliciesText/PoliciesText';
import {ValueEnabledModel} from '../../../domain/models/checkoutSettings/ValueEnabled.model';
import {classes} from './Checkboxes.st.css';
import {PolicyButtonLocation, PolicyType} from '../PolicyButtonWithDialog/PolicyButtonWithDialog';
import {AgreeToTermsAnalyticsEventParams} from '../../../domain/utils/analytics.utils';

export enum CheckboxesDataHooks {
  root = 'CheckboxesDataHooks.root',
  policyCheckbox = 'CheckboxesDataHooks.policyCheckbox',
  subscriptionCheckbox = 'CheckboxesDataHooks.subscriptionCheckbox',
  digitalPolicyCheckbox = 'CheckboxesDataHooks.digitalPolicyCheckbox',
  policyLabel = 'CheckboxesDataHooks.policyLabel',
  digitalPolicyLabel = 'CheckboxesDataHooks.digitalPolicyLabel',
  subscriptionLabel = 'CheckboxesDataHooks.subscriptionLabel',
  TermsAndConditionsButton = 'CheckboxesDataHooks.TermsAndConditionsButton',
  PrivacyPolicyButton = 'CheckboxesDataHooks.PrivacyPolicyButton',
  ReturnPolicyButton = 'CheckboxesDataHooks.ReturnPolicyButton',
  DigitalPolicyButton = 'CheckboxesDataHooks.DigitalPolicyButton',
}

export const Checkboxes = () => {
  const {
    checkoutSettingsStore: {checkoutSettings},
    checkoutStore: {checkout},
    navigationStore: {trackEvent},
    checkboxesStore: {
      toggleIsPolicyCheckboxChecked,
      toggleIsDigitalPolicyCheckboxChecked,
      toggleIsSubscriptionCheckboxChecked,
      isDigitalPolicyCheckboxChecked,
      isPolicyCheckboxChecked,
      isSubscriptionCheckboxChecked,
      wasFormSubmitted,
    },
  } = useControllerProps();
  const {termsAndConditions, privacyPolicy, returnPolicy, digitalPolicy} = checkoutSettings;
  const localeKeys = useLocaleKeys();

  const [policies] = useState(termsFieldLabels({termsAndConditions, privacyPolicy, returnPolicy}, localeKeys));

  const onPolicyChanged = ({checked}: {checked: boolean}) => {
    trackEvent(...AgreeToTermsAnalyticsEventParams);
    toggleIsPolicyCheckboxChecked(checked);
  };

  const onDigitalPolicyChanged = ({checked}: {checked: boolean}) => {
    trackEvent(...AgreeToTermsAnalyticsEventParams);
    toggleIsDigitalPolicyCheckboxChecked(checked);
  };

  return (
    <div data-hook={CheckboxesDataHooks.root} className={classes.root}>
      {checkoutSettings.areGeneralPoliciesEnabled && (
        <Checkbox
          required={true}
          error={!isPolicyCheckboxChecked && wasFormSubmitted}
          errorMessage={localeKeys.checkout.terms_and_condition.checkbox_error()}
          newErrorMessage={!isPolicyCheckboxChecked && wasFormSubmitted}
          data-hook={CheckboxesDataHooks.policyCheckbox}
          label={<PoliciesText dataHook={CheckboxesDataHooks.policyLabel} policies={policies} />}
          checked={isPolicyCheckboxChecked}
          onChange={onPolicyChanged}
        />
      )}

      {checkoutSettings.isDigitalPolicyEnabled && checkout.hasDigitalItems && (
        <Checkbox
          required={true}
          error={!isDigitalPolicyCheckboxChecked && wasFormSubmitted}
          errorMessage={localeKeys.checkout.terms_and_condition.checkbox_error()}
          newErrorMessage={!isPolicyCheckboxChecked && wasFormSubmitted}
          data-hook={CheckboxesDataHooks.digitalPolicyCheckbox}
          label={
            <PoliciesText
              dataHook={CheckboxesDataHooks.digitalPolicyLabel}
              policies={[digitalPolicyInfo(digitalPolicy, localeKeys)]}
            />
          }
          checked={isDigitalPolicyCheckboxChecked}
          onChange={onDigitalPolicyChanged}
        />
      )}
      {checkoutSettings.isSubscriptionEnabled && (
        <Checkbox
          data-hook={CheckboxesDataHooks.subscriptionCheckbox}
          label={
            <Text data-hook={CheckboxesDataHooks.subscriptionLabel}>{localeKeys.checkout.subscription.checkbox()}</Text>
          }
          checked={isSubscriptionCheckboxChecked}
          onChange={({checked}) => toggleIsSubscriptionCheckboxChecked(checked)}
        />
      )}
    </div>
  );
};

const termsFieldLabels = (
  {
    termsAndConditions,
    privacyPolicy,
    returnPolicy,
  }: {termsAndConditions: ValueEnabledModel; privacyPolicy: ValueEnabledModel; returnPolicy: ValueEnabledModel},
  localeKeys: ILocaleKeys
): PolicyInfo[] =>
  [
    {
      ...termsAndConditions,
      labelKey: 'checkout.terms_and_conditions.checkbox.terms_and_conditions',
      dialogTitle: localeKeys.checkout.terms_and_condition_modal.title(),
      dataHook: CheckboxesDataHooks.TermsAndConditionsButton,
      policyType: PolicyType.TermsAndConditions,
      location: PolicyButtonLocation.FORM,
    },
    {
      ...privacyPolicy,
      labelKey: 'checkout.terms_and_conditions.checkbox.privacy_policy',
      dialogTitle: localeKeys.checkout.privacy_policy_modal.title(),
      dataHook: CheckboxesDataHooks.PrivacyPolicyButton,
      policyType: PolicyType.PrivacyPolicy,
      location: PolicyButtonLocation.FORM,
    },
    {
      ...returnPolicy,
      labelKey: 'checkout.terms_and_conditions.checkbox.return_policy',
      dialogTitle: localeKeys.checkout.refund_cancellation_modal.title(),
      dataHook: CheckboxesDataHooks.ReturnPolicyButton,
      policyType: PolicyType.ReturnPolicy,
      location: PolicyButtonLocation.FORM,
    },
  ].filter(({enabled}) => enabled);

const digitalPolicyInfo = (digitalPolicy: ValueEnabledModel, localeKeys: ILocaleKeys): PolicyInfo => ({
  ...digitalPolicy,
  labelKey: 'checkout.terms_and_conditions.checkbox.digital_item_policy',
  dialogTitle: localeKeys.checkout.digital_item_policy_modal.title(),
  dataHook: CheckboxesDataHooks.DigitalPolicyButton,
  policyType: PolicyType.DigitalItemPolicy,
  location: PolicyButtonLocation.FORM,
});
