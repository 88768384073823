import {FormValues} from '@wix/form-viewer';
import {VatIDValue} from '@wix/form-fields';
import {ContactModel} from '../../../../domain/models/Contact.model';
import {getCommonVatId} from '../../../../domain/utils/vat.util';
import {VatIdFragment, WixCommonVatType} from '../../../../gql/graphql';

export const getVatFormInitialState = (contact?: ContactModel) => ({
  vat_id: {
    id: contact?.vatId?.id ?? '',
    type: contact?.vatId?.type ?? WixCommonVatType.UNSPECIFIED,
  },
});

export const getVatFromVatFormValues = (vatFormValues: FormValues): VatIdFragment | undefined => {
  return getCommonVatId(vatFormValues.vat_id as VatIDValue);
};
