import React from 'react';
import {SectionNotification, TextButton} from 'wix-ui-tpa/cssVars';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {classes} from './MemberAddressNotValidError.st.css';
import {Trans} from 'react-i18next';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {ADD_NEW_ADDRESS_ID} from '../../constants';
import {useMemberDetailsData} from '../WithMemberDetailsData';
import {useControllerProps} from '../../Widget/ControllerContext';
import {BiAddressActionOrigin} from '../../../../domain/utils/bi.util';
import {MemberAddressActionBiParams} from '../../../../types/app.types';

export enum AddressNotValidErrorDataHook {
  root = 'AddressNotValidError.root',
  content = 'AddressNotValidError.content',
  editButton = 'AddressNotValidError.editButton',
  addButton = 'AddressNotValidError.addButton',
}

export const MemberAddressNotValidError = () => {
  const {t, i18n} = useTranslation();
  const localeKeys = useLocaleKeys();
  const {selectedAddressesServiceId, setAddressServiceById, setEditMode} = useMemberDetailsData();
  const {
    memberStore: {reportEditAddressClicked, reportAddAddressClicked},
    stepsManagerStore: {activeStep},
    checkoutStore: {checkout},
  } = useControllerProps();

  const getActionBiParams = (): MemberAddressActionBiParams => ({
    addressServiceId: selectedAddressesServiceId,
    origin: BiAddressActionOrigin.AddressError,
    stage: activeStep.stepId,
    checkout,
  });

  return (
    <SectionNotification data-hook={AddressNotValidErrorDataHook.root} type="error">
      <SectionNotification.Icon icon={<ErrorIcon />} />
      <SectionNotification.Text data-hook={AddressNotValidErrorDataHook.content}>
        {`${localeKeys.checkout.address_information.error_message.missing_info()} `}
        <Trans
          i18n={i18n}
          t={t}
          i18nKey={localeKeys.checkout.address_information.error_message.suffix()}
          components={{
            1: (
              <TextButton
                key={'textButton'}
                data-hook={AddressNotValidErrorDataHook.editButton}
                className={classes.actionTextButton}
                onClick={() => {
                  setEditMode(true);
                  reportEditAddressClicked(getActionBiParams());
                }}
              />
            ),
            3: (
              <TextButton
                key={'textButton'}
                data-hook={AddressNotValidErrorDataHook.addButton}
                className={classes.actionTextButton}
                onClick={() => {
                  reportAddAddressClicked(getActionBiParams());
                  setAddressServiceById(ADD_NEW_ADDRESS_ID);
                }}
              />
            ),
          }}
        />
      </SectionNotification.Text>
    </SectionNotification>
  );
};
