import React from 'react';
import {Checkbox, Text} from 'wix-ui-tpa/cssVars';
import {classes} from './BillingDetails/BillingDetails.st.css';
import {useBillingData} from './WithBillingData/WithBillingData';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';

export enum BillingSameAsShippingCheckboxDataHook {
  billingSameAsShippingToggle = 'BillingSameAsShippingCheckbox.billingSameAsShippingToggle',
  billingSameAsShippingLabel = 'BillingSameAsShippingCheckbox.billingSameAsShippingLabel',
}

export const BillingSameAsShippingCheckbox = () => {
  const {setBillingSameAsShipping, billingSameAsShipping} = useBillingData();

  const localeKeys = useLocaleKeys();

  return (
    <Checkbox
      className={classes.sameAsShippingToggle}
      data-hook={BillingSameAsShippingCheckboxDataHook.billingSameAsShippingToggle}
      label={
        <Text data-hook={BillingSameAsShippingCheckboxDataHook.billingSameAsShippingLabel}>
          {localeKeys.checkout.billing_information.billing_same_as_delivery_label()}
        </Text>
      }
      checked={billingSameAsShipping}
      onChange={({checked}) => setBillingSameAsShipping(checked)}
    />
  );
};
