import React from 'react';
import {DeliveryMethodStepDataHook} from '../DeliveryMethodStep';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {CollapsedShippingInfo} from './CollapsedShippingInfo/CollapsedShippingInfo';
import {PickupInfoBox} from '../../../../PickupInfoBox/PickupInfoBox';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../constants';
import {classes} from './DeliveryMethodCollapsed.st.css';

export enum DeliveryMethodSummaryDataHook {
  root = 'ContactAndAddressSummary.root',
}

export const DeliveryMethodCollapsed = () => {
  const {
    checkoutStore: {checkout},
  } = useControllerProps();
  const {experiments} = useExperiments();

  return (
    <div data-hook={DeliveryMethodStepDataHook.collapsed} className={classes.root}>
      {(!checkout.selectedShippingOption?.pickupInfo || experiments.enabled(SPECS.ShowTimeSlotsUIChanges)) && (
        <CollapsedShippingInfo />
      )}
      {checkout.selectedShippingOption?.pickupInfo && (
        <PickupInfoBox pickupInfo={checkout.selectedShippingOption.pickupInfo} />
      )}
    </div>
  );
};
