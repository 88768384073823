import {Text, TextButton, TextPriority} from 'wix-ui-tpa/cssVars';
import React, {forwardRef} from 'react';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {classes, st} from './Step.st.css';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {StepState} from '../../../../../types/app.types';

export interface StepHeaderProps {
  label: string;
  dataHook: string;
  index: number;
  hideEditButton?: boolean;
}

export enum StepHeaderDataHooks {
  headerText = 'StepHeaderDataHooks.headerText',
  editButton = 'StepHeaderDataHooks.editButton',
}

// eslint-disable-next-line react/display-name
export const StepHeader = forwardRef<HTMLDivElement, StepHeaderProps>(
  ({label, dataHook, index, hideEditButton}: StepHeaderProps, ref) => {
    const localeKeys = useLocaleKeys();
    const {
      memberStore: {isMember},
      stepsManagerStore: {openStep, stepsList},
    } = useControllerProps();

    const editButtonText = isMember ? localeKeys.checkout.change() : localeKeys.checkout.edit();
    const stepState = stepsList[index].state;

    return (
      <div className={classes.stepTitleContainer} data-hook={dataHook} ref={ref}>
        <div className={st(classes.stepTitle, stepState === StepState.EMPTY ? classes.emptyStepTitle : undefined)}>
          <Text
            tagName={'h2'}
            priority={stepState === StepState.EMPTY ? TextPriority.secondary : TextPriority.primary}
            className={stepState === StepState.EMPTY ? classes.emptyStepTitleText : classes.titleText}
            data-hook={StepHeaderDataHooks.headerText}>
            {label}
          </Text>
        </div>
        {stepState === StepState.COLLAPSED && !hideEditButton && (
          <div className={classes.stepEditTitleWrapper}>
            <TextButton
              className={classes.stepEditTitle}
              key={'textButton'}
              onClick={() => openStep(index)}
              data-hook={StepHeaderDataHooks.editButton}>
              {editButtonText}
            </TextButton>
          </div>
        )}
      </div>
    );
  }
);
