import {StreetAddressFragment} from '../../gql/graphql';

export class StreetAddressModel {
  public name?: string;
  public number?: string;
  constructor(streetAddress: StreetAddressFragment) {
    this.name = streetAddress.name ?? /* istanbul ignore next */ undefined;
    this.number = streetAddress.number ?? /* istanbul ignore next */ undefined;
  }
}
