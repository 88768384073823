import React from 'react';
import {DropdownTheme, Dropdown, Card} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {classes} from './ScheduledDeliverySelector.st.css';
import {ScheduledDeliveryOptionModel} from '../../../../domain/models/ScheduledDeliveryOption.model';

export interface ScheduledDeliverySubtextProps {
  scheduledDeliveryOptions: ScheduledDeliveryOptionModel[];
  selectedId?: string;
  disabled?: boolean;
  onSelect: (shippingOptionId: string) => void;
}

export enum ScheduledDeliverySelectorDataHook {
  root = 'ScheduledDeliverySelectorDataHook.root',
  dropdown = 'ScheduledDeliverySelectorDataHook.dropdown',
}

export const ScheduledDeliverySelector = ({
  scheduledDeliveryOptions,
  onSelect,
  disabled,
  selectedId,
}: ScheduledDeliverySubtextProps) => {
  const localeKeys = useLocaleKeys();

  const dropdownOptions = scheduledDeliveryOptions.map(({code, deliveryTime}) => {
    return {id: code, value: deliveryTime, isSelectable: true};
  });

  return (
    <div className={classes.root}>
      <Card data-hook={ScheduledDeliverySelectorDataHook.root} className={classes.card}>
        <Dropdown
          label={localeKeys.checkout.deliveryMethod.selectOption.lable()}
          disabled={disabled}
          upgrade={true}
          initialSelectedId={selectedId}
          data-hook={ScheduledDeliverySelectorDataHook.dropdown}
          onChange={(option) => onSelect(option.id!)}
          theme={DropdownTheme.Box}
          options={dropdownOptions}
          className={classes.dropdown}
        />
      </Card>
    </div>
  );
};
