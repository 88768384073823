import {VatIdFragment, WixCommonVatType} from '../../gql/graphql';

export const getCommonVatId = ({id, type}: {id?: string; type?: string}): VatIdFragment | undefined => {
  if (id) {
    return {
      id,
      type: getCommonVatType(type),
    };
  }
  return undefined;
};

const getCommonVatType = (type?: string): WixCommonVatType => {
  switch (type) {
    /* istanbul ignore next */ case 'CPF':
      return /* istanbul ignore next */ WixCommonVatType.CPF;
    case 'CNPJ':
      return /* istanbul ignore next */ WixCommonVatType.CNPJ;
    /* istanbul ignore next */ default:
      return /* istanbul ignore next */ WixCommonVatType.UNSPECIFIED;
  }
};
