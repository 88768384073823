import React, {Children, ReactElement} from 'react';
import {classes} from './Totals.st.css';
import {TotalsRow} from './Partials/TotalsRow/TotalsRow';
import {TotalsSection} from './Partials/TotalsSection/TotalsSection';

export enum TotalsDataHook {
  TotalsTable = 'totals-table',
  // eslint-disable-next-line no-shadow
  TotalsRow = 'total-row',
  TotalsRowTitle = 'total-row-title',
  TotalsRowValue = 'total-row-value',
  // eslint-disable-next-line no-shadow
  TotalsSection = 'totals-section',
  TotalsSectionTitle = 'totals-section-title',
}

export interface TotalsProps {
  children?: (ReactElement | false)[] | ReactElement | false;
  dataHook?: string;
}

export const Totals = ({children, dataHook}: TotalsProps) => {
  const flattenedChildren: ReactElement[] = (Children.toArray(children) as ReactElement[]).reduce(
    (accumulator: ReactElement[], currentValue: ReactElement) => accumulator.concat(currentValue),
    []
  );
  const sections = flattenedChildren.filter(
    (child) =>
      (child?.type && (child.type as React.ComponentType<any>).displayName === TotalsSection.displayName) ||
      (child.type as React.ComponentType<any>).displayName === TotalsRow.displayName
  );

  return (
    <div data-hook={dataHook ?? /* istanbul ignore next */ TotalsDataHook.TotalsTable} className={classes.tableWrapper}>
      {sections}
    </div>
  );
};

Totals.displayName = 'Totals';
Totals.TotalsRow = TotalsRow;
Totals.TotalsSection = TotalsSection;
