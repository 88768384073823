import {ITrackEventName, ITrackEventParams} from '@wix/native-components-infra';

const enum PaymentMethodType {
  PAYPAL = 'paypal',
}

const enum EventAction {
  AgreeToTerms = 'Agree To Terms',
}

const origin = 'Stores';
const eventCategory = 'Enhanced Ecommerce - Stores';

export const FastFlowAnalyticsEventParams = ['AddPaymentInfo', {option: PaymentMethodType.PAYPAL, origin}] as [
  ITrackEventName,
  ITrackEventParams
];

export const AgreeToTermsAnalyticsEventParams = [
  'CustomEvent',
  {
    eventCategory,
    action: EventAction.AgreeToTerms,
    eventLabel: origin,
  },
] as [ITrackEventName, Record<string, any>];

export const StartPayment = [
  'StartPayment' as ITrackEventName,
  {
    origin,
  },
] as [ITrackEventName, Record<string, any>];

export const AddPaymentInfo = ({activePaymentMethod}: {activePaymentMethod?: string}) =>
  [
    'AddPaymentInfo' as ITrackEventName,
    {
      origin,
      option: activePaymentMethod,
    },
  ] as [ITrackEventName, Record<string, any>];
