import React, {ReactNode} from 'react';
import {AddressDropdown} from './AddressDropdown/AddressDropdown';
import {MemberDetailsSummary} from './MemberDetailsSummary/MemberDetailsSummary';
import {classes} from './MemberDetails.st.css';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {MemberAddressNotValidError} from './MemberAddressNotValidError/MemberAddressNotValidError';
import {useMemberDetailsData} from './WithMemberDetailsData';
import {CustomFieldModel} from '../../../domain/models/CustomField.model';
import {Checkbox, Text} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {SetAsDefaultCheckbox} from './SetAsDefaultCheckbox/SetAsDefaultCheckbox';
import {useControllerProps} from '../Widget/ControllerContext';

export enum MemberDetailsDataHook {
  root = 'MemberDetails.root',
  memberForm = 'MemberDetails.memberForm',
  addNewCheckbox = 'MemberDetails.addNewCheckbox',
  addNewCheckboxLabel = 'MemberDetails.addNewCheckboxLabel',
}

export interface MemberDetailsProps {
  Form: () => ReactNode;
  dropdownLabel: string;
  customField?: CustomFieldModel;
}

export const MemberDetails = ({Form, dropdownLabel, customField}: MemberDetailsProps) => {
  const {areMemberDetailsValid, selectedAddressesServiceId, editMode, isAddNewChecked, setIsAddNewChecked} =
    useMemberDetailsData();
  const {
    memberStore: {addressesInfo},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const shouldShowAddressesDropdown = selectedAddressesServiceId && addressesInfo.addresses?.length > 0;

  return (
    <div data-hook={MemberDetailsDataHook.root} className={classes.root}>
      {shouldShowAddressesDropdown && <AddressDropdown dropdownLabel={dropdownLabel} />}
      {selectedAddressesServiceId && selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID && !editMode && (
        <>
          <MemberDetailsSummary customField={customField} />
          {!areMemberDetailsValid && <MemberAddressNotValidError />}
        </>
      )}
      {(!selectedAddressesServiceId || selectedAddressesServiceId === ADD_NEW_ADDRESS_ID || editMode) && (
        <div data-hook={MemberDetailsDataHook.memberForm}>{Form()}</div>
      )}
      {selectedAddressesServiceId && (selectedAddressesServiceId === ADD_NEW_ADDRESS_ID || editMode) && (
        <SetAsDefaultCheckbox />
      )}
      {!selectedAddressesServiceId && (
        <Checkbox
          className={classes.formCheckbox}
          label={
            <Text data-hook={MemberDetailsDataHook.addNewCheckboxLabel}>
              {localeKeys.checkout.saveAddress.checkbox()}
            </Text>
          }
          checked={isAddNewChecked}
          data-hook={MemberDetailsDataHook.addNewCheckbox}
          onChange={({checked}) => setIsAddNewChecked(checked)}
        />
      )}
    </div>
  );
};
