import {classes} from './MainLoader.st.css';
import React from 'react';

export const MainLoaderDataHook = 'main-loader';

export const MainLoader = () => {
  return (
    <div key={'main-loader'} data-hook={MainLoaderDataHook} className={classes.root}>
      <div key={'loader-first'} className={classes.first}></div>
      <div key={'loader-second'} className={classes.second}></div>
      <div key={'loader-third'} className={classes.third}></div>
    </div>
  );
};
