import React from 'react';
import {SectionNotification} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import {DeliveryMethodSectionDataHook} from '../DeliveryMethodSection';
import {useControllerProps} from '../../Widget/ControllerContext';

export const UnsupportedShippingDestinationError = () => {
  const localeKeys = useLocaleKeys();
  const {checkoutStore} = useControllerProps();
  const externalCarrierError = checkoutStore.checkout.errors.externalCarrierError;

  return (
    <SectionNotification data-hook={DeliveryMethodSectionDataHook.noShippingMessage} type="error">
      <SectionNotification.Icon icon={<ErrorIcon />} />
      <SectionNotification.Text>
        {externalCarrierError
          ? externalCarrierError?.data?.description
          : localeKeys.checkout.deliveryMethod.error.unsupportedRegion()}
      </SectionNotification.Text>
    </SectionNotification>
  );
};
