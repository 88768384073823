export type PaymentError = {
  translatedError?: string;
  failureDetails?: string;
  status: 'DECLINED' | 'BUYER_CANCELED';
};

export function isAPaymentError(obj: any): boolean {
  return !!obj && typeof obj !== 'string' && isAPaymentErrorType(obj);
}
export function isAPaymentErrorType(obj: any): obj is PaymentError {
  return 'status' in obj;
}
