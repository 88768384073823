import * as React from 'react';
import {classes} from './ItemSubscription.st.css';
import {Text} from 'wix-ui-tpa/cssVars';
import {LineItemDataHooks} from '../LineItem';

export interface SubscriptionProps {
  subscription: {name: string; duration: string};
}

export enum SubscriptionDataHook {
  Name = 'SubscriptionDataHook.Name',
  Duration = 'SubscriptionDataHook.Duration',
}

export class ItemSubscription extends React.PureComponent<SubscriptionProps> {
  public render() {
    const {subscription} = this.props;

    return (
      <div className={classes.root} data-hook={LineItemDataHooks.Subscription}>
        <Text data-hook={SubscriptionDataHook.Name} className={classes.text}>
          {subscription.name}
        </Text>
        <Text data-hook={SubscriptionDataHook.Duration} className={classes.text}>
          {subscription.duration}
        </Text>
      </div>
    );
  }
}
